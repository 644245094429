/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
//utilities
import { rhythm } from "../utils/typography"
import { themeRules, themeMedia } from "../utils/theme"
import styled from "styled-components"
//SVGs
import TwitterLogo from "../../content/assets/_ionicons_svg_logo-twitter.svg"
import FacebookLogo from "../../content/assets/_ionicons_svg_logo-facebook.svg"
import LinkedinLogo from "../../content/assets/_ionicons_svg_logo-linkedin.svg"
import GithubLogo from "../../content/assets/_ionicons_svg_logo-github.svg"

//styled components
const FlexContainer = styled.div`
  margin-bottom: ${rhythm(2.5)};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: ${themeMedia.maxXsmall}) {
    flex-direction: column;
  }
`
const StyledImage = styled(Image)`
  max-width: 100px;
  max-height: 100px;
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  border-radius: 10%;
  box-shadow: ${({ theme }) => theme.baseBoxShadow};
  align-self: flex-start;
  z-index: 10;

  @media (max-width: ${themeMedia.maxXsmall}) {
    justify-self: center;
    margin: 0 auto;
    max-height: 200px;
    max-width: 200px;
    //border-radius: 50%;
  }
`
const StyledParagraph = styled.p`
  margin-bottom: 0;
  padding-top: ${rhythm(1 / 2)};
  padding-bottom: ${rhythm(1 / 4)};
  padding-right: ${rhythm(1 / 4)};
  padding-left: 3rem;
  background-color: ${({ theme }) => theme.cardBg};
  color: ${({ theme }) => theme.textColor};
  transition: ${({ theme }) => theme.baseThemeTransition};
  box-shadow: ${themeRules.baseBoxShadow};
  //border-top-left-radius: 15%;
  //border-radius: 23% 0% 0% 23%;
  transform: translateX(-2.5rem);

  @media (max-width: ${themeMedia.maxXsmall}) {
    transform: none;
    padding: ${rhythm(1 / 2)};
  }
`
const StyledSocialLinkContainer = styled.span`
  margin-top: ${rhythm(1 / 2)};
  width: 50%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: auto;
  margin-right: ${rhythm(1 / 2)};

  @media (max-width: ${themeMedia.maxXsmall}) {
    margin: 0 auto;
  }
`

const StyledSocialLink = styled.a`
  display: block;
  text-decoration: none;
  box-shadow: none;
  fill: ${({ theme }) => theme.textColor};
  transition: transform 0.1s ease;
  & :hover {
    transform: translateY(-5px) scale(1.06);
  }
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            facebook
            linkedin
            github
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <FlexContainer>
      <StyledImage fixed={data.avatar.childImageSharp.fixed} alt={author} />
      <StyledParagraph>
        Rédigé par moi : <strong>{author}</strong>, développeur web et mobile
        fan de JavaScript sous toutes ses formes, mais aussi de Swift. Sectaire
        en rien, passionné en tout. Éternel apprenant et ravi de l’être.
        {` `}
        <StyledSocialLinkContainer>
          <StyledSocialLink
            href={`https://twitter.com/${social.twitter}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Suivez-moi sur Twitter"
          >
            <TwitterLogo height="40" width="40" />
          </StyledSocialLink>
          <StyledSocialLink
            href={`https://facebook.com/${social.facebook}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Suivez-moi sur Facebook"
          >
            <FacebookLogo height="40" width="40" />
          </StyledSocialLink>
          <StyledSocialLink
            href={`https://linkedin.com/in/${social.linkedin}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Suivez-moi sur Linkedin"
          >
            <LinkedinLogo height="40" width="40" />
          </StyledSocialLink>
          <StyledSocialLink
            href={`https://github.com/${social.github}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Suivez-moi sur GitHub"
          >
            <GithubLogo height="40" width="40" />
          </StyledSocialLink>
        </StyledSocialLinkContainer>
      </StyledParagraph>
    </FlexContainer>
  )
}

export default Bio
