import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import IndexLayout from "../components/IndexLayout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import styled from "styled-components"
import { themeMedia } from "../utils/theme"

import PostCard from "../components/postCard"
import Newsletter from "../components/newsletter"

/**
 * Styled components
 */
const GridContainer = styled.div`
  display: grid;
  grid-template: 1fr 1fr 1fr / 1fr 1fr;
  grid-gap: ${rhythm(1)};
  align-items: start;

  @media (max-width: ${themeMedia.maxXsmall}) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
`
const PostCardGridItem = styled(PostCard)``

class BlogIndex extends React.Component {
  render() {
    //get data from GraphQL query
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    //used for pagination purpose
    const { currentPage, numPages } = this.props.pageContext
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? `` : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <IndexLayout location={this.props.location} title={siteTitle}>
        <SEO title="Accueil" />
        <Bio />
        <GridContainer>
          {posts.map(({ node }) => {
            return <PostCardGridItem data={node} key={node.fields.slug} />
          })}
        </GridContainer>
        {/*Pagination*/}
        <ul
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            listStyle: "none",
            padding: 0,
          }}
        >
          {!isFirstPage && (
            <li
              style={{
                margin: 0,
              }}
            >
              <Link to={`/${prevPage}`} rel="prev">
                Page Précédente
              </Link>
            </li>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number-${i + 1}`}
              style={{
                padding: rhythm(1 / 4),
                textDecoration: "none",

                background: i + 1 === currentPage ? "#007acc" : "",
                margin: 0,
              }}
            >
              <Link
                key={`pagination-number${i + 1}`}
                to={`/${i === 0 ? "" : i + 1}`}
                style={{ color: i + 1 === currentPage ? "#ffffff" : "" }}
              >
                {i + 1}
              </Link>
            </li>
          ))}
          {!isLastPage && (
            <li
              style={{
                margin: 0,
              }}
            >
              <Link to={`/${nextPage}`} rel="next">
                Page Suivante
              </Link>
            </li>
          )}
        </ul>
        <Newsletter />
      </IndexLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
