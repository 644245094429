import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp/src"
import styled from "styled-components"

const WrapperDiv = styled.div`
  background-color: ${({ theme }) => theme.cardBg};
  border-radius: 20px;
  padding: 1em;
  margin: 2em auto;
  max-width: 700px;
  & h2 {
    margin-top: 0;
  }
`
const FlexContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const InputStyled = styled.input`
  border: none;
  border-radius: 0.4rem;
  padding: 0.8em 0.5em;
  transition: box-shadow 0.3s ease-in;
  margin-bottom: 1.5em;
  :focus {
    outline: none;
    box-shadow: 0.1rem 0.4rem 0.8rem rgba(35, 35, 35, 0.71);
  }
`
const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.submitButtonBg};
  color: ${({ theme }) => theme.textColor};
  border: none;
  margin: 0.6em auto;
  width: 200px;
  border-radius: 10px;
  padding: 1em;
  //box-shadow: ${({ theme }) => theme.baseBoxShadow};

  & :hover {
    cursor: pointer;
  }

  & :active {
    box-shadow: none;
    transform: translateY(4px);
  }
`

const Newsletter = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [result, setResult] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()

    addToMailchimp(email, { FNAME: name })
      .then(data => {
        setResult(data.result)
      })
      .catch(error => {
        setError(error)
        // MailChimp always returns a 200, if we have any error it's client side
      })
  }

  const handleChange = e => {
    if (e.currentTarget.name === "email") {
      //email
      setEmail(e.currentTarget.value)
    }
    if (e.currentTarget.name === "name") {
      //name
      setName(e.currentTarget.value)
    }
  }

  return (
    <>
      {error && (
        <WrapperDiv>
          <h2>
            Oups !{" "}
            <span role="img" aria-label="emoji scream">
              😱
            </span>
          </h2>
          <p>
            Une terrible erreur vient de se produire ! Votre adhésion n'a pas
            fonctionné{" "}
            <span role="img" aria-label="emoji cold sweat">
              😰
            </span>
          </p>
        </WrapperDiv>
      )}
      {result && (
        <WrapperDiv>
          <h2>
            Merci !{" "}
            <span role="img" aria-label="emoji kissing heart">
              😘
            </span>
          </h2>
          <p>
            À bientôt dans votre boite mail. Si vous n'avez pas reçu mon message
            de bienvenue, vérifiez vos spams.{" "}
            <span role="img" aria-label="emoji winking">
              😉
            </span>
          </p>
        </WrapperDiv>
      )}
      {!result && (
        <form onSubmit={handleSubmit}>
          <WrapperDiv>
            <h2>Abonnez-vous à ma liste</h2>
            <p>
              Pas de spam ! Uniquement du contenu de qualité (en toute modestie
              bien sûr{" "}
              <span role="img" aria-label="emoji face with monocle">
                🧐
              </span>
              ) lié au développement web.
            </p>
            <p>
              Au programme du JavaScript, React, Node, JAMstack, serverless,
              CSS, Gatsby, Next.js et bien plus encore. Le tout dans la joie et
              la bonne humeur !
            </p>
            <FlexContainer>
              <InputStyled
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                placeholder="Prénom"
                aria-label="Prénom"
                required
              />
              <InputStyled
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                placeholder="Adresse email"
                aria-label="Email"
                required
              />

              <SubmitButton type="submit">S'abonner !</SubmitButton>
            </FlexContainer>
          </WrapperDiv>
        </form>
      )}
    </>
  )
}

export default Newsletter
